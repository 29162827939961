import React, { useState } from 'react';

import { PropTypes } from 'prop-types';

import { graphql } from 'gatsby';

import { useForm, ValidationError } from '@formspree/react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import bannerImg from '../images/download-books.jpeg';

import {
    Box,
    Button,
    Container,
    LinearProgress,
    TextField,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(
    ({ theme: { breakpoints, palette, spacing } }) => ({
        minWidth: '100%',
        backgroundColor: palette.grey[200],
        padding: spacing(10),
        height: '100vh',
        backgroundSize: 'contain',
    })
);

function Download(props) {
    const { name, publicURL } = props.data.file;
    console.log(publicURL, name, props.data);

    const [state, handleSubmit] = useForm('xjvnjvyl');

    const [formData, setFormData] = useState({
        name: '',
        company: '',
        designation: '',
        email: '',
    });

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <Layout>
            <StyledBox sx={{ backgroundImage: `url(${bannerImg})` }}>
                <Container maxWidth="sm">
                    <Typography color="primary" variant="h5" component="h2">
                        {state.succeeded
                            ? 'Your book is ready for download'
                            : state.submitting
                            ? 'Loading your book..'
                            : 'To download the book please enter a few details'}
                    </Typography>

                    {state.succeeded ? (
                        <Button
                            sx={{ p: 4, my: 2 }}
                            component="a"
                            href={publicURL}
                            download={`ebook-${name}`}
                            target="_blank"
                            rel="noopener"
                            variant="contained"
                            fullWidth
                        >
                            Download now
                        </Button>
                    ) : state.submitting ? (
                        <LinearProgress />
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <TextField
                                sx={{ my: 1, width: '100%' }}
                                label="Name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                variant="outlined"
                                required
                                disabled={state.submitting || state.succeeded}
                            />
                            <TextField
                                sx={{ my: 1, width: '100%' }}
                                label="Company"
                                name="company"
                                value={formData.company}
                                onChange={handleChange}
                                variant="outlined"
                                required
                                disabled={state.submitting || state.succeeded}
                            />
                            <TextField
                                required
                                sx={{ my: 1, width: '100%' }}
                                label="Designation"
                                name="designation"
                                value={formData.designation}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={state.submitting || state.succeeded}
                            />
                            <TextField
                                required
                                sx={{ my: 1, width: '100%' }}
                                label="Email Address"
                                name="email"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={state.submitting || state.succeeded}
                            />

                            <input
                                type="hidden"
                                name="book-name"
                                value={name}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={
                                    state.errors ||
                                    state.submitting ||
                                    state.succeeded
                                }
                            >
                                {state.submitting
                                    ? 'Submitting your details...'
                                    : 'Submit'}
                            </Button>

                            <ValidationError
                                prefix="Email"
                                field="email"
                                errors={state.errors}
                            />
                        </form>
                    )}
                </Container>
            </StyledBox>
        </Layout>
    );
}

export const Head = () => {
    return <Seo title="Product Management Books | Free, downloadable guides" />;
};

export default Download;

export const pageQuery = graphql`
    query DownloadQuery($fileName: String!) {
        file(name: { eq: $fileName }) {
            id
            name
            publicURL
        }
    }
`;
